<template>
  <section class="user-listing">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex mb-4">
              <h5 class="mr-2 font-weight-semibold border-right pr-2 mr-2">Users</h5>
              <h5 class="font-weight-semibold">430</h5>
            </div>
            <div class="row mx-sm-0">
              <div class="col-md-12 mb-5 pt-2 border px-0">
                <div class="card rounded shadow-none">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6 col-lg-5 d-lg-flex">
                        <div class="user-avatar mb-auto">
                          <img src="@/assets/images/faces/face1.jpg" alt="profile image" class="profile-img img-lg rounded-circle">
                          <span class="edit-avatar-icon"><i class="icon-camera"></i></span>
                        </div>
                        <div class="wrapper pl-lg-4">
                          <div class="wrapper d-flex align-items-center">
                            <h4 class="mb-0 font-weight-medium">Danny Santiago</h4>
                            <div class="badge badge-secondary mt-2 ml-2">Pro</div>
                          </div>
                          <div class="wrapper d-flex align-items-center font-weight-medium text-muted">
                            <i class="icon-map mr-2"></i>
                            <p class="mb-0 text-muted">San Francisco, California</p>
                          </div>
                          <div class="wrapper d-flex align-items-start pt-3">
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-check icon-sm"></i>
                            </div>
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-envelope-open icon-sm"></i>
                            </div>
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-list icon-sm"></i>
                            </div>
                            <div class="wrapper pl-2 d-none d-sm-block">
                              <h6 class="mt-n1 mb-0 font-weight-medium">23455</h6>
                              <p class="text-muted">Viewers</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-lg-5">
                        <div class="d-flex align-items-center w-100">
                          <p class="mb-0 mr-3 font-weight-semibold">Progress</p>
                          <div class="progress progress-md w-100">
                            <div class="progress-bar bg-success" role="progressbar" style="width: 67%" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <p class="mb-0 ml-3 font-weight-semibold">67%</p>
                        </div>
                        <p class="text-muted mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                      </div>
                      <div class="col-sm-6 col-lg-2">
                        <div class="wrapper d-flex">
                          <button type="button" class="btn btn-sm btn-primary mr-2">ASK</button>
                          <button type="button" class="btn btn-sm btn-info">HIRE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wrapper border-top">
                    <div class="card-body">
                      <div class="row">
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-disc icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">TOTAL TRAFFIC</p>
                            <h4 class="font-weight-semibold mb-0">350,897</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-user icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">NEW USERS</p>
                            <h4 class="font-weight-semibold mb-0">2,356</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-key icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">SALES</p>
                            <h4 class="font-weight-semibold mb-0">927</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-pie-chart icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">PERFORMANCE</p>
                            <h4 class="font-weight-semibold mb-0 text-primary">49.65%</h4>
                          </div>
                        </div>
                        <div class="col d-flex align-items-center">
                          <div class="image-grouped ml-auto">
                            <img src="@/assets/images/faces/face20.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Mary Sharp">
                            <img src="@/assets/images/faces/face17.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Cory Medina">
                            <img src="@/assets/images/faces/face14.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Clyde Hammond">
                            <div class="text-avatar" data-toggle="tooltip" data-placement="top" title="" data-original-title="4 More Peoples"><span class="d-block pt-2">+4</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-5 pt-2 border px-0">
                <div class="card rounded shadow-none">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6 col-lg-5 d-lg-flex">
                        <div class="user-avatar mb-auto">
                          <img src="@/assets/images/faces/face2.jpg" alt="profile image" class="profile-img img-lg rounded-circle">
                          <span class="edit-avatar-icon"><i class="icon-camera"></i></span>
                        </div>
                        <div class="wrapper pl-lg-4">
                          <div class="wrapper d-flex align-items-center">
                            <h4 class="mb-0 font-weight-medium">Lelia Bowers</h4>
                          </div>
                          <div class="wrapper d-flex align-items-center font-weight-medium text-muted">
                            <i class="icon-map mr-2"></i>
                            <p class="mb-0 text-muted">Central African Republic</p>
                          </div>
                          <div class="wrapper d-flex align-items-start pt-3">
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-check icon-sm"></i>
                            </div>
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-envelope-open icon-sm"></i>
                            </div>
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-list icon-sm"></i>
                            </div>
                            <div class="wrapper pl-2 d-none d-sm-block">
                              <h6 class="mt-n1 mb-0 font-weight-medium">23525</h6>
                              <p class="text-muted">Viewers</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-lg-5">
                        <div class="d-flex align-items-center w-100">
                          <p class="mb-0 mr-3 font-weight-semibold">Progress</p>
                          <div class="progress progress-md w-100">
                            <div class="progress-bar bg-success" role="progressbar" style="width: 45%" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <p class="mb-0 ml-3 font-weight-semibold">45%</p>
                        </div>
                        <p class="text-muted mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                      </div>
                      <div class="col-sm-6 col-lg-2">
                        <div class="wrapper d-flex">
                          <button type="button" class="btn btn-sm btn-primary mr-2">ASK</button>
                          <button type="button" class="btn btn-sm btn-info">HIRE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wrapper border-top">
                    <div class="card-body">
                      <div class="row">
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-disc icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">TOTAL TRAFFIC</p>
                            <h4 class="font-weight-semibold mb-0">443,235</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-user icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">NEW USERS</p>
                            <h4 class="font-weight-semibold mb-0">3,433</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-key icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">SALES</p>
                            <h4 class="font-weight-semibold mb-0">345</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-pie-chart icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">PERFORMANCE</p>
                            <h4 class="font-weight-semibold mb-0 text-primary">34.43%</h4>
                          </div>
                        </div>
                        <div class="col d-flex align-items-center">
                          <div class="image-grouped ml-auto">
                            <img src="@/assets/images/faces/face11.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Mary Sharp">
                            <img src="@/assets/images/faces/face12.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Cory Medina">
                            <img src="@/assets/images/faces/face23.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Clyde Hammond">
                            <div class="text-avatar" data-toggle="tooltip" data-placement="top" title="" data-original-title="4 More Peoples"><span class="d-block pt-2">+4</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-5 pt-2 border px-0">
                <div class="card rounded shadow-none">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6 col-lg-5 d-lg-flex">
                        <div class="user-avatar mb-auto">
                          <img src="@/assets/images/faces/face3.jpg" alt="profile image" class="profile-img img-lg rounded-circle">
                          <span class="edit-avatar-icon"><i class="icon-camera"></i></span>
                        </div>
                        <div class="wrapper pl-lg-4">
                          <div class="wrapper d-flex align-items-center">
                            <h4 class="mb-0 font-weight-medium">Tommy Jenkins</h4>
                          </div>
                          <div class="wrapper d-flex align-items-center font-weight-medium text-muted">
                            <i class="icon-map mr-2"></i>
                            <p class="mb-0 text-muted">Bouvet Island</p>
                          </div>
                          <div class="wrapper d-flex align-items-start pt-3">
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-check icon-sm"></i>
                            </div>
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-envelope-open icon-sm"></i>
                            </div>
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-list icon-sm"></i>
                            </div>
                            <div class="wrapper pl-2 d-none d-sm-block">
                              <h6 class="mt-n1 mb-0 font-weight-medium">34526</h6>
                              <p class="text-muted">Viewers</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-lg-5">
                        <div class="d-flex align-items-center w-100">
                          <p class="mb-0 mr-3 font-weight-semibold">Progress</p>
                          <div class="progress progress-md w-100">
                            <div class="progress-bar bg-success" role="progressbar" style="width: 77%" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <p class="mb-0 ml-3 font-weight-semibold">77%</p>
                        </div>
                        <p class="text-muted mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                      </div>
                      <div class="col-sm-6 col-lg-2">
                        <div class="wrapper d-flex">
                          <button type="button" class="btn btn-sm btn-primary mr-2">ASK</button>
                          <button type="button" class="btn btn-sm btn-info">HIRE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wrapper border-top">
                    <div class="card-body">
                      <div class="row">
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-disc icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">TOTAL TRAFFIC</p>
                            <h4 class="font-weight-semibold mb-0">563,645</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-user icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">NEW USERS</p>
                            <h4 class="font-weight-semibold mb-0">2,543</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-key icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">SALES</p>
                            <h4 class="font-weight-semibold mb-0">765</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-pie-chart icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">PERFORMANCE</p>
                            <h4 class="font-weight-semibold mb-0 text-primary">23.56%</h4>
                          </div>
                        </div>
                        <div class="col d-flex align-items-center">
                          <div class="image-grouped ml-auto">
                            <img src="@/assets/images/faces/face8.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Mary Sharp">
                            <img src="@/assets/images/faces/face7.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Cory Medina">
                            <img src="@/assets/images/faces/face9.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Clyde Hammond">
                            <div class="text-avatar" data-toggle="tooltip" data-placement="top" title="" data-original-title="4 More Peoples"><span class="d-block pt-2">+4</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-5 pt-2 border px-0">
                <div class="card rounded shadow-none">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6 col-lg-5 d-lg-flex">
                        <div class="user-avatar mb-auto">
                          <img src="@/assets/images/faces/face6.jpg" alt="profile image" class="profile-img img-lg rounded-circle">
                          <span class="edit-avatar-icon"><i class="icon-camera"></i></span>
                        </div>
                        <div class="wrapper pl-lg-4">
                          <div class="wrapper d-flex align-items-center">
                            <h4 class="mb-0 font-weight-medium">Ramiz Dedaković</h4>
                          </div>
                          <div class="wrapper d-flex align-items-center font-weight-medium text-muted">
                            <i class="icon-map mr-2"></i>
                            <p class="mb-0 text-muted">Saint Kitts and Nevis</p>
                          </div>
                          <div class="wrapper d-flex align-items-start pt-3">
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-check icon-sm"></i>
                            </div>
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-envelope-open icon-sm"></i>
                            </div>
                            <div class="badge badge-secondary mr-2">
                              <i class="icon-list icon-sm"></i>
                            </div>
                            <div class="wrapper pl-2 d-none d-sm-block">
                              <h6 class="mt-n1 mb-0 font-weight-medium">69434</h6>
                              <p class="text-muted">Viewers</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-lg-5">
                        <div class="d-flex align-items-center w-100">
                          <p class="mb-0 mr-3 font-weight-semibold">Progress</p>
                          <div class="progress progress-md w-100">
                            <div class="progress-bar bg-success" role="progressbar" style="width: 55%" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <p class="mb-0 ml-3 font-weight-semibold">55%</p>
                        </div>
                        <p class="text-muted mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                      </div>
                      <div class="col-sm-6 col-lg-2">
                        <div class="wrapper d-flex">
                          <button type="button" class="btn btn-sm btn-primary mr-2">ASK</button>
                          <button type="button" class="btn btn-sm btn-info">HIRE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wrapper border-top">
                    <div class="card-body">
                      <div class="row">
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-disc icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">TOTAL TRAFFIC</p>
                            <h4 class="font-weight-semibold mb-0">343,525</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-user icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">NEW USERS</p>
                            <h4 class="font-weight-semibold mb-0">5,324</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-key icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">SALES</p>
                            <h4 class="font-weight-semibold mb-0">235</h4>
                          </div>
                        </div>
                        <div class="col d-flex">
                          <div class="d-inline-flex align-items-center justify-content-center border rounded-circle px-2 py-2 my-auto text-muted">
                            <i class="icon-pie-chart icon-sm my-0 "></i>
                          </div>
                          <div class="wrapper pl-3">
                            <p class="mb-0 font-weight-medium text-muted">PERFORMANCE</p>
                            <h4 class="font-weight-semibold mb-0 text-primary">45.23%</h4>
                          </div>
                        </div>
                        <div class="col d-flex align-items-center">
                          <div class="image-grouped ml-auto">
                            <img src="@/assets/images/faces/face13.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Mary Sharp">
                            <img src="@/assets/images/faces/face14.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Cory Medina">
                            <img src="@/assets/images/faces/face4.jpg" data-toggle="tooltip" data-placement="top" title="" alt="profile image" data-original-title="Clyde Hammond">
                            <div class="text-avatar" data-toggle="tooltip" data-placement="top" title="" data-original-title="4 More Peoples"><span class="d-block pt-2">+2</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'userListing'
}
</script>